.react-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 52vw;
  max-width: 1360px;
  /* border: 2px solid #efefef; */
  background: white;
  border-radius: 5px;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #3f80ab;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  background: inherit;
  display: inline-block;
  /* border: 1px solid transparent; */
  border-bottom: none;
  /* bottom: -1px; */
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  color: #3f80ab;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;

  transition: all 0.5s ease;
}

.react-tabs__tab:hover {
}

.react-tabs__tab--selected {
  background: #3f80ab;
  border-color: #3f80ab;
  color: #fff;
  border-radius: 0;
}
.react-tabs__tab--selected:first-child {
  border-radius: 5px 0 0 0;
}

.react-tabs__tab--disabled {
  color: #a6a6a6;
  cursor: not-allowed;
  background-color: #f6f6f6;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  /* content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff; */
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
